<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="优惠券类型">
          <el-select v-model="orderType" placeholder="请选择" clearable>
            <el-option
              v-for="item in orders"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用情况">
          <el-select v-model="status" placeholder="请选择" clearable>
            <el-option
              v-for="item in useStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="payList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column
          label="消费券名称"
          prop="map.commodityCouponEntity.name"
        ></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="发放时间" prop="create_time"></el-table-column>
        <el-table-column label="用户id" prop="user_id"></el-table-column>
        <el-table-column label="使用状态">
          <template slot-scope="{ row }">
            <div v-if="row.status == 1" style="color: green">未使用</div>
            <div v-if="row.status == 2">已失效</div>
            <div v-if="row.status == 3">已使用</div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" @click="getUserInfo(row)"
              >查看用户</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="payTotal"
      ></paging-fy>
    </el-row>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :width="ks ? '90%' : '30%'"
      :before-close="handleClose"
    >
      <el-descriptions title="用户信息" :column="2">
        <el-descriptions-item label="用户名">{{
          user.nick_name
        }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{
          user.mobile
        }}</el-descriptions-item>
        <el-descriptions-item label="id">{{
          user.user_id
        }}</el-descriptions-item>
        <el-descriptions-item label="性别">{{
          sex(user.sex)
        }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payList: [],
      ks: "",
      nav: { firstNav: "优惠券管理中心", secondNav: "优惠券发放记录" },
      is_loading: true,
      dialogVisible: false,
      is_loading: false,
      currentPage: 1,
      payTotal: 0,
      temp: {},
      payList: [],
      options: [
        {
          value: "true",
          label: "已支付",
        },
        {
          value: "false",
          label: "未支付",
        },
      ],
      phone: "",
      mechanism_name_list: [],
      amount: "",
      nickname: "",
      orderType: "新人券",
      orders: [
        {
          value: "新人券",
          label: "新人券",
        },
        {
          value: "商户券",
          label: "商户券",
        },
        {
          value: "消费券",
          label: "消费券",
        },
        {
          value: "天赋课程券",
          label: "天赋课程券",
        },
      ],
      status: 1,
      useStatus: [
        {
          value: "1",
          label: "未使用",
        },
        {
          value: "2",
          label: "已过期",
        },
        {
          value: "3",
          label: "已使用",
        },
      ],
      user: {},
    };
  },
  updated() {
    this.ks = window.screen.width < 768;
  },
  mounted() {
    this.getRefoundList();
  },
  methods: {
    sex(v) {
      if (v == 0) {
        return "未知";
      }
      if (v == 1) {
        return "男";
      }
      if (v == 2) {
        return "女";
      }
    },
    //id查用户信息
    getUserInfo(row) {
      let url = "/user/userInfo/findById";
      this.$axios
        .get(url, {
          params: {
            id: row.user_id,
          },
        })
        .then((res) => {
          this.user = res.data.data;
          this.dialogVisible = true;
        });
    },
    touser(row) {
      this.$router.push("/main/userDetails/" + row.user_id);
    },
    //订单
    getRefoundList() {
      this.is_loading = true;
      let url = "/user/userCoupon/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
      };
      if (this.orderType) {
        params.type = this.orderType;
      }
      if (this.status) {
        params.status = this.status;
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.payList = res.data.data.rows;
          this.payTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getRefoundList();
    },
    changeCurrentPage(v) {
      console.log(v);
      this.currentPage = v;
      this.getRefoundList();
    },
    refuse(row) {
      this.temp = row;
      this.refuseReason = true;
    },
    showRefuse(row) {
      console.log(row);
      this.temp = row;
      this.showReason = true;
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.refuseReason = false;
          this.showReason = false;
          this.temp = {};
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="less">
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;
  .el-form-item {
    width: 40%;
  }
}
</style>
