import { render, staticRenderFns } from "./couponLog.vue?vue&type=template&id=1675d0fe&"
import script from "./couponLog.vue?vue&type=script&lang=js&"
export * from "./couponLog.vue?vue&type=script&lang=js&"
import style0 from "./couponLog.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports